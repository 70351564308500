<template>
  <div ref="dialogue-box" class="dialogue-box">
    <ul class="dialogue-box__wrapper">
      <li v-for="(message, index) in messages" class="dialogue-box__message" :key="'message' + index">
          <p>
            <span v-if="message.actor !== 'Narrator'" class="dialogue-box__character-name" :style="'color: ' + message.charColor || '#ffffff'">{{message.actor}}</span>
            <span v-if="message.actor !== 'Narrator'"> — </span>
            <span :style="'color: ' + (message.actor === 'Narrator' ? '#ffffff95' : '#ffffff')">{{message.text}}</span>
          </p>
      </li>
    </ul>
    <ul class="dialogue-box__reply-wrapper">
      <li v-for="(reply, index) in replies" @mouseenter="makeScrambledText(reply, index)" @mouseleave="clearScrambledText" @click="selectReply(reply)" class="dialogue-box__reply" :key="'reply' + index">
          <p><i>fig {{index + 1}}.</i> - <span :ref="'reply-' + index" class="dialogue-box__reply-option-text">{{scrambledText.reply === index ? scrambledText.text : reply.text}}</span></p>
      </li>
    </ul>
    <button @click="startDialogue" v-if="!dialogueStarted" class="dialogue-box__start">Begin</button>
    <button v-if="continueActive" @click="handleContinue" class="dialogue-box__start">Continue</button>
  </div>
</template>

<script>
export default {
  name: 'MainSection',
  data () {
    return {
      latinAndNumericals: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      scrambledText: {
        reply: null,
        text: ''
      }
    }
  },
  props: {
    messages: {
      type: Array
    },
    replies: {
      type: Array
    },
    dialogueStarted: {
      type: Boolean
    },
    continueActive: {
      type: Boolean
    }
  },
  methods: {
    startDialogue () {
      this.$emit('startDialogue')
    },
    selectReply (reply) {
      this.clearScrambledText()
      this.$emit('selectReply', reply)
    },
    handleContinue () {
      this.$emit('pushMessage', this.messages[this.messages.length - 1].next.actor, this.messages[this.messages.length - 1].next.messageCode)
    },
    getRandomCharacter () {
      return this.latinAndNumericals.charAt(Math.floor(Math.random() * this.latinAndNumericals.length))
    },
    makeScrambledText (reply, index) {
      if (reply.cipherText) {
        let duration = 500
        let interval = 25
        let currentText = reply.text.split("")
        const finalText = reply.cipherText.split("")
        this.scrambledText.reply = index
        let startTime = Date.now()
        const scrambleInterval = setInterval(() => {
          let elapsed = Date.now() - startTime
          let progress = Math.min(elapsed / duration, 1)
          for (let i = 0; i < currentText.length; i++) {
            if (Math.random() < progress) {
              currentText[i] = finalText[i] || ""
            } else {
              currentText[i] = this.getRandomCharacter()
            }
          }
          this.scrambledText.text = currentText.join("")
          if (progress === 1) clearInterval(scrambleInterval)
        }, interval);
      }
    },
    clearScrambledText () {
      this.scrambledText = {
        reply: null,
        text: ''
      }
    },
    scrollToBottom () {
      const dialogueBox = this.$refs['dialogue-box']
      dialogueBox.scrollTop = dialogueBox.scrollHeight
    }
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  },
}
</script>

<style scoped>
.dialogue-box {
  margin-left: auto;
  background: rgba(32, 30, 29, 0.801);
  height: 100%;
  box-sizing: border-box;
  width: 380px;
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}
.dialogue-box::-webkit-scrollbar {
  display: none;
}
.dialogue-box__wrapper {
  font-size: 18px;
  list-style: none;
  padding: 0;
}
.dialogue-box__reply-wrapper {
  font-size: 18px;
  list-style: none;
}

.dialogue-box__reply p {
  margin: 0;
  cursor: pointer;
}

.dialogue-box__reply-option-text {
  color: #da3e3eda;
}

.dialogue-box__reply:hover .dialogue-box__reply-option-text {
  color: #FFF;
}

.dialogue-box__character-name {
  text-transform: uppercase;
}
.dialogue-box__start {
  font-family: 'IM Fell English', sans-serif;
  color: white;
  font-size: 18px;
  background: #da3e3eda;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
}
</style>