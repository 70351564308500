<template>
  <div class="main-section">
    <div class="main-section__content">
      <h1 class="main-section__title">Don't Call Abigail</h1>
      <DialogueBox 
        :messages="messages"
        :replies="replies"
        :continueActive="continueActive"
        :dialogueStarted="dialogueStarted"
        @pushMessage="pushMessage"
        @selectReply="selectReply"
        @startDialogue="startDialogue"
      />
    </div>
    <audio ref="dialogue-audio" src="" />
  </div>
</template>

<script>
import DialogueBox from './DialogueBox.vue'
export default {
  name: 'MainSection',
  components: {
    DialogueBox
  },
  data () {
    return {
      dialogueStarted: false,
      continueActive: false,
      messagesPool: {
        'Nature Broadcast Presenter': {
          color: '#80EF80',
          'Intro 1': {
            text: "Here\xa0we see a truly outstanding specimen...",
            audio: "ATTEN-COMP-01.wav",
            replies: 'Intro 1'
          },
          'Intro 2': {
            text: "...wallowing in its natural habitat, amidst mud and refuse, and what can only be put in a gentle society as the contents of his own stomach.",
            audio: "ATTEN-COMP-02.wav",
            replies: 'Intro 2'
          }
        },
        'Nature Broadcast Tormentor': {
          color: '#80EF80',
          'Intro 3': {
            text: "His mind a tangle, only good for repeating his signature mating call:",
            audio: "ATTEN-COMP-03.wav",
            replies: 'Intro 3'
          },
          'Intro 4': {
            text: "The mysterious, elusive, pedestrian yet one-in-a-billion...",
            audio: "ATTEN-COMP-04.wav",
            replies: 'Intro 4'
          },
          'Intro 5': {
            text: "Dumb Fuck.",
            audio: "ATTEN-COMP-05.wav",
            replies: 'Intro 5'
          }
        },
        'Narrator': {
          narrator: true,
          'Intro 6': {
            text: "You are shivering from a terrible cold, assaulting you in frigid waves. The air is filled with a wretched smell. **This** happened again. **You** allowed this to happen. **She** may not forgive you this time.",
            audio: "NARR-01.wav",
            continue: true,
            next: {
              actor: "Lesions",
              messageCode: "Intro 7"
            }
          } 
        },
        'Lesions': {
          color: '#ffffb7',
          'Intro 7': {
            text: "She shouldn't have forgiven you the first time. This is just who you are.",
            replies: "Intro 7",
            audio: "LESIO-01.wav"
          },
          'Intro 8': {
            text: "Damn right, my boy. Don't call Abigail. Your body is too frail to withstand her rejecting you. And she sees **all**.",
            continue: true,
            audio: "LESIO-02.wav",
            next: {
              actor: 'Lesions',
              messageCode: "Intro 9",
            }
          },
          'Intro 9': {
            text: "All of your sins.",
            audio: "LESIO-03.wav",
            replies: "Intro 9"
          },
          'Intro 10': {
            text: "All of them. You didn't even look at the menu, you ordered **all of it**. That's your modus operandi. All or nothing.",
            replies: "Intro 10",
            audio: "LESIO-04.wav",
          },
          'Intro 11': {
            text: "And how it will be.",
            continue: true,
            audio: "LESIO-05.wav",
            next: {
              actor: 'Shape Resembling a Spine',
              messageCode: "Intro 12"
            }
          },
          'Intro 13.1': {
            text: "That's the spirit!",
            audio: "LESIO-06.wav",
            continue: true,
            next: {
              actor: 'Shape Resembling a Spine',
              messageCode: "Intro 14.2"
            }
          },
          'Intro 14.2': {
            text: "Don't call Abigail, though.",
            audio: "LESIO-07.wav",
            continue: true,
            next: {
              actor: 'Shape Resembling a Spine',
              messageCode: 'Intro 15'
            }
          },
          'Intro 16': {
            text: "...in another life...",
            audio: "LESIO-08.wav",
            continue: true,
            next: {
              actor: 'Shape Resembling a Spine',
              messageCode: 'Intro 17'
            }
          }
        },
        'Shape Resembling a Spine': {
          color: '#6667AB',
          'Intro 12': {
            text: "It doesn't have to.",
            audio: "SHAPE-PITCH-01.wav",
            replies: "Intro 12"
          },
          'Intro 13.2': {
            text: "That is precisely why you must repent. Salvation is out there. But you must stop actively shoveling damage at it.",
            continue: true,
            audio: "SHAPE-PITCH-03.wav",
            next: {
              actor: 'Lesions',
              messageCode: 'Intro 14.2'
            } 
          },
          'Intro 14.2': {
            text: "She knows everything. But there is yet time. Stop accumulating shit, because there is enough of it to sort out as is.",
            continue: true,
            audio: "SHAPE-PITCH-02.wav",
            next: {
              actor: 'Shape Resembling a Spine',
              messageCode: 'Intro 15'
            }
          },
          'Intro 15': {
            text: "But now wake up...",
            audio: "SHAPE-PITCH-04.wav",
            continue: true,
            next: {
              actor: 'Lesions',
              messageCode: 'Intro 16'
            }
          },
          'Intro 17': {
            text: "...by the seaside.",
            audio: "SHAPE-PITCH-05.wav"
          }
        }
      },
      repliesPool: {
        'Intro 1': [
          {
            text: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Nature Broadcast Presenter",
              messageCode: "Intro 2"
            }
          },
          {
            text: "Attempt to turn off the broadcast",
            cipherText: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Nature Broadcast Presenter",
              messageCode: "Intro 2"
            }
          }
        ],
        'Intro 2': [
          {
            text: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Nature Broadcast Tormentor",
              messageCode: "Intro 3"
            }
          },
          {
            text: `"What kind of monster are you?"`,
            cipherText: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Nature Broadcast Tormentor",
              messageCode: "Intro 3"
            }
          }
        ],
        'Intro 3': [
          {
            text: `"Don't call Abigail..."`,
            cipherText: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Nature Broadcast Tormentor",
              messageCode: "Intro 4"
            }
          },
          {
            text: `"Mate yourself, buddy"`,
            cipherText: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Nature Broadcast Tormentor",
              messageCode: "Intro 4"
            }
          }
        ],
        'Intro 4': [
          {
            text: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Nature Broadcast Tormentor",
              messageCode: "Intro 5"
            }
          },
          {
            text: `"Please, make it stop..."`,
            cipherText: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Nature Broadcast Tormentor",
              messageCode: "Intro 5"
            }
          }
        ],
        'Intro 5': [
          {
            text: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Narrator",
              messageCode: "Intro 6"
            }
          },
          {
            text: `"Please, call Abigail..."`,
            cipherText: `"Don't call Abigail..."`,
            leadsTo: {
              actor: "Narrator",
              messageCode: "Intro 6"
            }
          },
          {
            text: `"Thank goodness, for a moment I thought he was talking about me."`,
            leadsTo: {
              actor: "Narrator",
              messageCode: "Intro 6"
            }
          }
        ],
        'Intro 7': [
          {
            text: `"Don't call Abigail?"`,
            leadsTo: {
              actor: "Lesions",
              messageCode: "Intro 8"
            }
          }
        ],
        'Intro 9': [
          {
            text: `"What are my sins?"`,
            leadsTo: {
              actor: "Lesions",
              messageCode: "Intro 10"
            }
          }
        ],
        'Intro 10': [
          {
            text: `"Is this how it has always been?"`,
            leadsTo: {
              actor: "Lesions",
              messageCode: "Intro 11"
            }
          }
        ],
        'Intro 12': [
          {
            text: `"It doesn't have to **not** be like that."`,
            leadsTo: {
              actor: "Lesions",
              messageCode: "Intro 13.1"
            }
          },
          {
            text: `"But how can I repent? She is always watching me, she knows all of my sins..."`,
            leadsTo: {
              actor: "Shape Resembling a Spine",
              messageCode: "Intro 13.2"
            }
          }
        ]
      },
      messages: [],
      replies: [],
      dialogueAudio: null,
      clickAudio: new Audio(require(`../assets/audio/CLICK.wav`)),
      successAudio: new Audio(require(`../assets/audio/SUCCESS.mp3`)),
      musicAudio: new Audio(require(`../assets/audio/music/COMMUNIST-HELL.mp3`))
    }
  },
  methods: {
    startDialogue () {
      this.dialogueStarted = true
      this.successAudio.play()
      setTimeout(() => {
        this.musicAudio.volume = 0.4
        this.musicAudio.play()
      }, 1200)
      setTimeout(() => {
        this.pushMessage('Nature Broadcast Presenter', 'Intro 1')
      }, 2000)
    },
    selectReply (reply) {
      const selectedReply = {
        actor: 'You',
        text: reply.cipherText ? reply.cipherText : reply.text
      }
      this.messages.push(selectedReply)
      this.pushMessage(reply.leadsTo.actor, reply.leadsTo.messageCode)
    },
    pushMessage (actor, messageCode) {
      this.continueActive = false
      this.replies = []
      const text = this.messagesPool[actor][messageCode].text
      this.clickAudio.play()
      this.dialogueAudio?.pause()
      if (this.messagesPool[actor][messageCode].audio) {
        this.dialogueAudio = new Audio(require(`../assets/audio/dialogue/${this.messagesPool[actor][messageCode].audio}`))
        this.dialogueAudio.play()
      }
      const charColor = this.messagesPool[actor].color
      const message = {
        actor: actor,
        text: text,
        charColor: charColor  
      }
      this.messages.push(message)
      if (this.messagesPool[actor][messageCode].continue) {
        this.continueActive = true
        message.next = this.messagesPool[actor][messageCode].next
      } 
      this.replies = this.repliesPool[this.messagesPool[actor][messageCode].replies]
    }
  }
}
</script>

<style scoped>
.main-section {
  background-image: url('../assets/imgs/film-texture-2.jpg');
  height: 100vh;
  width: 100vw;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-section__content {
  max-width: 1080px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-section__title {
  margin: 0;
  background: #da3e3eda;
  padding: 0 10px;
  width: fit-content;
}
</style>
