<template>
  <div id="app">
    <MainSection />
  </div>
</template>

<script>
import MainSection from './components/MainSection.vue'

export default {
  name: 'App',
  components: {
    MainSection
  }
}
</script>

<style>
#app {
  font-family: 'IM Fell English', sans-serif;
  color: white;
}
body {
  margin: 0;
}
</style>
